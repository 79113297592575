



























import {Vue,Component,Prop} from 'vue-property-decorator';
import {State,Action} from 'vuex-class';
import {ObtenerFlujoAprobacion,FlujoAprobacionModal} from '@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta';
import Loading from '@/entidades/Sistema/Loading';
var namespaced = "authModule";
@Component({
  name : 'FlujoAprobacionComponent'

})
export default class FlujoAprobacionComponent extends Vue 
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    
    
    @Prop({type:Number}) parteId!:number;

    timelineResponse:FlujoAprobacionModal[] = [];

    async obtenerFlujo()
    {
        try 
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            let response = await ObtenerFlujoAprobacion(this.parteId);
            if(response.isSuccess)
            {
                this.timelineResponse = response.isResult;
            }
        }
        catch(error)
        {

        }
        finally 
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    mounted()
    {
        this.obtenerFlujo();
    }
}
